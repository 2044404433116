
import React, { useEffect, useState, useRef } from "react";
import Icon from "@mui/material/Icon";
import './style.css'

export default function DropZone({ label, fileType, onChange, inititalFiles = [], origFiles = [] }) {
    const types = [];
    switch(fileType){
        case 'images':
            types.push('image/png');
            types.push('image/gif');
            types.push('image/jpeg');
            break;
    }

    const [files, setFiles] = useState(inititalFiles);
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0] && types.includes(e.dataTransfer.files[0].type)) {
            setFiles([...files, e.dataTransfer.files[0]]);
            onChange(files.map((f, i) => {
                if(typeof f == 'string' && f.indexOf('https') !== false && origFiles[i]){
                    return origFiles[i];
                }
                return f;
            }));
        }
    };

    const handleChange = (e) => {
        if(e.target.files.length){
            setFiles([...files, e.target.files[0]]);
            onChange(files.map((f, i) => {
                console.log('true', origFiles[i], i, origFiles);
                if(typeof f == 'string' && f.indexOf('https') !== false && origFiles[i]){
                    return origFiles[i];
                }
                return f;
            }));
        }
    }

    const handleRemove = (e) => {
        const i = e.target.dataset.index;
        if(files[i]){
            files.splice(i, 1);
            setFiles([...files]);
            onChange(files.map((f, i) => {
                if(typeof f == 'string' && f.indexOf('https') !== false && origFiles[i]){
                    return origFiles[i];
                }
                return f;
            }));
        }
    }

    useEffect(async () => {
        onChange(files.map((f, i) => {
            if(typeof f == 'string' && f.indexOf('https') !== false && origFiles[i]){
                return origFiles[i];
            }
            return f;
        }));
    }, [files]);

    return (
        <div
            className={`dropZone ${files.length > 0 ? 'not-title' : ''} ${dragActive ? 'dragActive' : ''}`}
            data-label={label}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <input type="file" accept={types.join(',')} onChange={handleChange} ref={inputRef} multiple={true} />
            { files && files.map((f, i) => {
                return (
                    <div className="dropZone-image">
                        <img src={typeof f == 'string' ? f : URL.createObjectURL(f)} />
                        <Icon fontSize="small" color="inherit" className="remove" data-index={i} onClick={handleRemove}>close</Icon>
                    </div>
                )
            }) }
        </div>
    )
}