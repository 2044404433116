/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function Admins() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Логин", accessor: "login", align: "left" },
    { Header: "Имя", accessor: "name", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];

  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Логин (латиница)',
      name: 'login',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Имя',
      name: 'name',
    },
    {
      type: 'TextField',
      typeInput: 'password',
      label: 'Пароль',
      name: 'password',
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    login: '',
    name: '',
    password: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [users, setUsers] = useState([]);
  const [usersOrig, setUsersOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [userEdit, setUserEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [userDelete, setUserDelete] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  
  const [userEditResponse, setUserEditResponse] = useState(false);
  const [userEditResponseShow, setUserEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(userEdit !== false){
      setShowEdit(true);
    }
    if(userEditResponse !== false){
      setUserEditResponseShow(true);
      setTimeout(setUserEditResponseShow, 3000, false);
    }
    if(userDelete !== false){
      setShowDelete(true);
    }
  }, [userEdit, userEditResponse, userDelete]);

  useEffect(async () => {
    const users_d = await DataService.getAdmins();
    if(users_d){
      const clone = Object.assign([], users_d);
      setUsersOrig(clone);

      const users_d_table = [];
      users_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.login = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.login}</MDTypography>)
        e.name = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.name}</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-user={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-user={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        users_d_table.push(e);
      })
      setUsers(users_d_table);

    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let user = false;
    if(e.target.tagName == 'BUTTON'){
      user = e.target.dataset.user;
    }else if(e.target.tagName == 'SPAN'){
      user = e.target.parentElement.dataset.user;
    }
    setUserEdit(+user);
  };

  const handleShowDeleteModal = (e) => {
    let user = false;
    if(e.target.tagName == 'BUTTON'){
      user = e.target.dataset.user;
    }else if(e.target.tagName == 'SPAN'){
      user = e.target.parentElement.dataset.user;
    }
    setUserDelete(+user);
  };

  const handleShowAddModal = (e) => {
    setShowAdd(true);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowAdd(false);
    setUserEdit(false);
    setUserDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveAdmins(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setUserEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setUserEdit(false);
      setUserDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteAdmins(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setUserEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setUserEdit(false);
      setUserDelete(false);
    }
  };

  const handleAddModal = async (e) => {
    const res = await DataService.addAdmins(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setUserEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setUserEdit(false);
      setUserDelete(false);
    }
  };

  const toggleSnackbar = () => setUserEditResponseShow(!userEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Администраторы панели управления бота
                  <MDBox display="inline" ml={2}>
                    <MDButton variant="gradient" color="success" size="small" pr={2} iconOnly type="button" onClick={handleShowAddModal} ml={1}><Icon fontSize="small">add</Icon></MDButton>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: users }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={userEdit}
        orig={usersOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование администратора"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={userDelete}
        orig={usersOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление администратора"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить администратора?!"
      />
      <DialogMain
        show={showAdd}
        handleClose={handleCloseModal}
        handleSave={handleAddModal}
        keyOrig={0}
        orig={[]}
        form={formSave}
        defaultValue={defaultForm}
        title="Добавление администратора"
        titleSave="Добавить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с администратором"
        dateTime='Только что'
        content={userEditResponse}
        open={userEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Admins;
