/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function Schedules() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Пользователь", accessor: "username", align: "left" },
    { Header: "Тип", accessor: "type", align: "left" },
    { Header: "Интервал", accessor: "interval", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextFieldSelect',
      label: 'Тип',
      name: 'type',
      items: [
        {
          id: 1,
          title: 'Прием лекарств'
        },
        {
          id: 2,
          title: 'Пить воду'
        }
      ]
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Интервал',
      name: 'interval',
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    type: 1,
    interval: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [schedules, setSchedules] = useState([]);
  const [schedulesOrig, setSchedulesOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [scheduleEdit, setScheduleEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [scheduleDelete, setScheduleDelete] = useState(false);
  
  const [scheduleEditResponse, setScheduleEditResponse] = useState(false);
  const [scheduleEditResponseShow, setScheduleEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(scheduleEdit !== false){
      setShowEdit(true);
    }
    if(scheduleEditResponse !== false){
      setScheduleEditResponseShow(true);
      setTimeout(setScheduleEditResponseShow, 3000, false);
    }
    if(scheduleDelete !== false){
      setShowDelete(true);
    }
  }, [scheduleEdit, scheduleEditResponse, scheduleDelete]);

  useEffect(async () => {
    const schedule_d = await DataService.getSchedules();
    if(schedule_d){
      const clone = Object.assign([], schedule_d);
      setSchedulesOrig(clone);

      const schedule_d_table = [];
      schedule_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        if(e.type == 1){
          e.type = (<MDBox ml={-1}>
            <MDBadge badgeContent="Прием лекарств" color="success" variant="gradient" size="sm" />
          </MDBox>)
        }else{
          e.type = (<MDBox ml={-1}>
            <MDBadge badgeContent="Пить воду" color="success" variant="gradient" size="sm" />
          </MDBox>)
        }
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.username = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.username}</MDTypography>)
        e.interval = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.interval} Минут</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-schedule={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-schedule={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        schedule_d_table.push(e);
      })
      setSchedules(schedule_d_table);

    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let schedule = false;
    if(e.target.tagName == 'BUTTON'){
      schedule = e.target.dataset.schedule;
    }else if(e.target.tagName == 'SPAN'){
      schedule = e.target.parentElement.dataset.schedule;
    }
    setScheduleEdit(+schedule);
  };

  const handleShowDeleteModal = (e) => {
    let schedule = false;
    if(e.target.tagName == 'BUTTON'){
      schedule = e.target.dataset.schedule;
    }else if(e.target.tagName == 'SPAN'){
      schedule = e.target.parentElement.dataset.schedule;
    }
    setScheduleDelete(+schedule);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setScheduleEdit(false);
    setScheduleDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveSchedules(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setScheduleEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setScheduleEdit(false);
      setScheduleDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteSchedule(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setScheduleEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setScheduleEdit(false);
      setScheduleDelete(false);
    }
  };

  const toggleSnackbar = () => setScheduleEditResponseShow(!scheduleEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Напоминания пользователей бота
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: schedules }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={scheduleEdit}
        orig={schedulesOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование напоминания"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={scheduleDelete}
        orig={schedulesOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление напоминания"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить напоминание?!"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с напоминанием"
        dateTime='Только что'
        content={scheduleEditResponse}
        open={scheduleEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Schedules;
