/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function News() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Заголовок", accessor: "title", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Заголовок',
      name: 'title',
      prop: {
        required: true
      },
    },
    {
      type: 'DateTimeCalendar',
      label: 'Отложенная публикация',
      name: 'date_publish',
      format: 'YYYY-MM-DD H:m',
    },
    {
      type: 'TextEditor',
      typeInput: 'text',
      label: 'Введите контент новости',
      name: 'description',
      prop: {
        required: true
      }
    },
    {
      type: 'FilesUpload',
      fileType: 'images',
      label: 'Изображения новости',
      name: 'files'
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    title: '',
    description: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [news, setNews] = useState([]);
  const [newsOrig, setNewsOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [newsEdit, setNewsEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [newsDelete, setNewsDelete] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  
  const [newsEditResponse, setNewsEditResponse] = useState(false);
  const [newsEditResponseShow, setNewsEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(newsEdit !== false){
      setShowEdit(true);
    }
    if(newsEditResponse !== false){
      setNewsEditResponseShow(true);
      setTimeout(setNewsEditResponseShow, 3000, false);
    }
    if(newsDelete !== false){
      setShowDelete(true);
    }
  }, [newsEdit, newsEditResponse, newsDelete]);

  useEffect(async () => {
    const news_d = await DataService.getNews();
    if(news_d){
      const clone = Object.assign([], news_d);
      setNewsOrig(clone);

      const events_d_table = [];
      news_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.title = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.title}</MDTypography>)
        e.date = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.date}</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-news={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-news={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        events_d_table.push(e);
      })
      setNews(events_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let news = false;
    if(e.target.tagName == 'BUTTON'){
      news = e.target.dataset.news;
    }else if(e.target.tagName == 'SPAN'){
      news = e.target.parentElement.dataset.news;
    }
    setNewsEdit(+news);
  };

  const handleShowDeleteModal = (e) => {
    let news = false;
    if(e.target.tagName == 'BUTTON'){
      news = e.target.dataset.news;
    }else if(e.target.tagName == 'SPAN'){
      news = e.target.parentElement.dataset.news;
    }
    setNewsDelete(+news);
  };

  const handleShowAddModal = (e) => {
    setShowAdd(true);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowAdd(false);
    setNewsEdit(false);
    setNewsDelete(false);
  };

  const handleSaveModal = async (e) => {
    if(e.files.length > 0){
      e.files = await uploadImages(e);
    }
    const res = await DataService.saveNews(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setNewsEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setNewsEdit(false);
      setNewsDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteNews(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setNewsEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setNewsEdit(false);
      setNewsDelete(false);
    }
  };

  const handleAddModal = async (e) => {
    if(e.files.length > 0){
      e.files = await uploadImages(e);
    }
    const res = await DataService.addNews(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setNewsEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setNewsEdit(false);
      setNewsDelete(false);
    }
  };

  const toggleSnackbar = () => setNewsEditResponseShow(!newsEditResponseShow);

  const uploadImages = async(e) => {
    return new Promise(async (resolve, reject) => {
      const images = e.files.filter(f => typeof f == 'string');
      if(e.files.length){
        Promise.all(e.files.filter(f => typeof f != 'string').map(async (f) => await DataService.uploadImage(f))).then(values => {
          values.map(resp => {
            if(resp.status){
              images.push(resp.url);
            }
          })
          resolve(images);
        });
      }
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Новости
                  <MDBox display="inline" ml={2}>
                    <MDButton variant="gradient" color="success" size="small" pr={2} iconOnly type="button" onClick={handleShowAddModal} ml={1}><Icon fontSize="small">add</Icon></MDButton>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: news }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={newsEdit}
        orig={newsOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование новости"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={newsDelete}
        orig={newsOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление новости"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить новость?!"
      />
      <DialogMain
        show={showAdd}
        handleClose={handleCloseModal}
        handleSave={handleAddModal}
        keyOrig={0}
        orig={[]}
        form={formSave}
        defaultValue={defaultForm}
        title="Добавление новости"
        titleSave="Добавить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с новостью"
        dateTime='Только что'
        content={newsEditResponse}
        open={newsEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default News;
