/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function Clinic() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Название", accessor: "title", align: "left" },
    { Header: "Адрес", accessor: "address", align: "left" },
    { Header: "Статус", accessor: "status", align: "left" },
    { Header: "Сортировка", accessor: "sort", align: "left" },
    { Header: "Обращений", accessor: "requests", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Название',
      name: 'title',
    },
    {
      type: 'Switch',
      label: 'Статус',
      name: 'status',
      prop: {
        required: true
      }
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Адрес',
      name: 'address',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Телефон',
      multiple: true,
      name: 'phone[0]',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'E-Mail',
      name: 'email',
    },
    {
      type: 'TextEditor',
      typeInput: 'text',
      label: 'Описание',
      name: 'description',
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    title: '',
    description: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [clinic, setClinic] = useState([]);
  const [clinicOrig, setClinicOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [clinicEdit, setClinicEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [clinicDelete, setClinicDelete] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  
  const [clinicEditResponse, setClinicEditResponse] = useState(false);
  const [clinicEditResponseShow, setClinicEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(clinicEdit !== false){
      setShowEdit(true);
    }
    if(clinicEditResponse !== false){
      setClinicEditResponseShow(true);
      setTimeout(setClinicEditResponseShow, 3000, false);
    }
    if(clinicDelete !== false){
      setShowDelete(true);
    }
  }, [clinicEdit, clinicEditResponse, clinicDelete]);

  useEffect(async () => {
    const clinic_d = await DataService.getClinics();
    if(clinic_d){
      const clone = Object.assign([], clinic_d);
      setClinicOrig(clone);

      const events_d_table = [];
      clinic_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.title = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.title}</MDTypography>)
        e.date = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.date}</MDTypography>)
        if(e.status == 1){
          e.status = (<MDBadge badgeContent="Вкл." color="success" variant="gradient" size="sm" data-key={i} />)
        }else {
          e.status = (<MDBadge badgeContent="Выкл." color="warning" variant="gradient" size="sm" data-key={i} />)
        }
        e.sort = (<MDInput
          type="text"
          fullWidth
          value={e.sort}
          inputProps={{ 'data-id':e.id }}
          name='sort'
          onChange={handleChangeSort}
        />)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-clinic={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-clinic={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        events_d_table.push(e);
      })
      setClinic(events_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let clinic = false;
    if(e.target.tagName == 'BUTTON'){
      clinic = e.target.dataset.clinic;
    }else if(e.target.tagName == 'SPAN'){
      clinic = e.target.parentElement.dataset.clinic;
    }
    setClinicEdit(+clinic);
  };

  const handleShowDeleteModal = (e) => {
    let clinic = false;
    if(e.target.tagName == 'BUTTON'){
      clinic = e.target.dataset.clinic;
    }else if(e.target.tagName == 'SPAN'){
      clinic = e.target.parentElement.dataset.clinic;
    }
    setClinicDelete(+clinic);
  };

  const handleShowAddModal = (e) => {
    setShowAdd(true);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowAdd(false);
    setClinicEdit(false);
    setClinicDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveClinic(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setClinicEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setClinicEdit(false);
      setClinicDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteClinic(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setClinicEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setClinicEdit(false);
      setClinicDelete(false);
    }
  };

  const handleAddModal = async (e) => {
    const res = await DataService.addClinic(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setClinicEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setClinicEdit(false);
      setClinicDelete(false);
    }
  };

  const toggleSnackbar = () => setClinicEditResponseShow(!clinicEditResponseShow);

  const handleChangeSort = async (e) => {
    if (e.target.dataset.hasOwnProperty('id')) {
      const res = await DataService.sortClinic(e);
      if(res && res.status){
        setRefreshKey(oldKey => oldKey +1)
        setClinicEditResponse(res.message);
        setShowEdit(false);
        setShowDelete(false);
        setShowAdd(false);
        setClinicEdit(false);
        setClinicDelete(false);
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Поликлиники
                  <MDBox display="inline" ml={2}>
                    <MDButton variant="gradient" color="success" size="small" pr={2} iconOnly type="button" onClick={handleShowAddModal} ml={1}><Icon fontSize="small">add</Icon></MDButton>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: clinic }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={clinicEdit}
        orig={clinicOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование поликлиники"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={clinicDelete}
        orig={clinicOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление поликлиники"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить поликлинику и все её обращения?!"
      />
      <DialogMain
        show={showAdd}
        handleClose={handleCloseModal}
        handleSave={handleAddModal}
        keyOrig={0}
        orig={[]}
        form={formSave}
        defaultValue={defaultForm}
        title="Добавление поликлиники"
        titleSave="Добавить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с поликлиникой"
        dateTime='Только что'
        content={clinicEditResponse}
        open={clinicEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Clinic;
