import React, { useRef, useImperativeHandle, useEffect, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Avatar,
    Grid,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Chat = ({ show, handleClose, handleSave, keyOrig, orig, defaultValue, title }) => {
    const elem = orig[keyOrig] || defaultValue;
    const [input, setInput] = React.useState("");

    const handleSend = () => {
        if (input.trim() !== "") {
            handleSave({ reply_text: input, message: elem.messages[elem.messages.findLastIndex((e) => e.type == 1)] });
            setInput("");
        }
    };

  const handleInputChange = (event) => setInput(event.target.value);

  const refMessage = useRef();
  useEffect(() => {
    setTimeout(() => {
      if(refMessage.current){
        refMessage.current.scrollTop = refMessage.current.scrollHeight;
      }
    }, 50)
  }, [elem]);

  return <div>
      <Dialog open={show} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <DialogTitle>{`${title}${elem.client}`}</DialogTitle>
        <DialogContent
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                bgcolor: "grey.200",
            }}
        >
            <Box sx={{ flexGrow: 1, overflow: "auto", p: 2, display: 'flex', flexDirection: 'column' }} ref={refMessage}>
            {elem.messages.map((message) => (
              <div>
                <Message key={message.id} message={message} />
                {message.reply.map((r) => (
                  <Message key={r.id} message={r} />
                ))}
              </div>
            ))}
            </Box>
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <TextField
                            size="small"
                            fullWidth
                            placeholder="Введите сообщение"
                            variant="outlined"
                            value={input}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            color="primary"
                            style={{ color: 'white' }}
                            variant="contained"
                            endIcon={<SendIcon />}
                            onClick={handleSend}
                        >
                        Отправить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  ;
};

const Message = ({ message }) => {
  const isBot = message.type === 2;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-end" : "flex-start",
        mb: 2,
        flexDirection: "column",
        alignItems: isBot ? 'end' : null,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row-reverse" : "row",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: isBot ? "#298ff4" : "#29a9e6" }}>
          {isBot ? "B" : "U"}
        </Avatar>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            ml: isBot ? 0 : 1,
            mr: isBot ? 1 : 0,
            backgroundColor: isBot ? "#298ff4" : "#29a9e6",
            borderRadius: isBot ? "20px 20px 5px 20px" : "20px 20px 20px 5px"
          }}
        >
          <Typography variant="body1" style={{color: 'white', fontSize: '11px', textShadow: '1px 1px 0px black', lineHeight: 1, textAlign: isBot ? 'right' : 'left'}}>{message.username}</Typography>
          <Typography variant="body1" style={{color: "white"}}>{message.text}</Typography>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "block",
          flexDirection: isBot ? "row-reverse" : "row",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography variant="caption" display="block">{message.date}</Typography>
      </Box>
    </Box>
  );
};

export default Chat;