/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Users from "layouts/users";
import Admins from "layouts/admins";
import Schedules from "layouts/schedules";
import Feedback from "layouts/feedback";
import Events from "layouts/events";
import News from "layouts/news";
import Clinic from "layouts/clinic";
import SystemMessage from "layouts/system-message";
import Menu from "layouts/menu";
import Settings from "layouts/settings";
import RequestsClinic from "layouts/requests-clinic";
import TemplatesReply from "layouts/templates-reply";

import Login from "auth/login";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Информация",
    title: 'Информация',
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Пользователи",
    key: "users",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Администраторы",
    key: "admins",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/admins",
    component: <Admins />,
  },
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "Напоминания",
    key: "schedules",
    icon: <Icon fontSize="small">schedules</Icon>,
    route: "/schedules",
    component: <Schedules />,
  },
  {
    type: "collapse",
    name: "Обращения",
    key: "feedback",
    icon: <Icon fontSize="small">feedback</Icon>,
    route: "/feedback",
    component: <Feedback />,
  },
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "События",
    key: "events",
    icon: <Icon fontSize="small">events</Icon>,
    route: "/events",
    component: <Events />,
  },
  {
    type: "collapse",
    name: "Новости",
    key: "news",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/news",
    component: <News />,
  },
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "Поликлиники",
    key: "clinic",
    icon: <Icon fontSize="small">vaccines</Icon>,
    route: "/clinic",
    component: <Clinic />,
  },
  {
    type: "collapse",
    name: "Обращения поликлиник",
    key: "requests-clinic",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/requests-clinic",
    component: <RequestsClinic />,
  },
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "Шаблоны ответов",
    key: "templates-reply",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/templates-reply",
    component: <TemplatesReply />,
  },
  {
    type: "collapse",
    name: "Меню",
    key: "menu",
    icon: <Icon fontSize="small">menu</Icon>,
    route: "/menu",
    component: <Menu />,
  },
  {
    type: "collapse",
    name: "Системные сообщения",
    key: "system-message",
    icon: <Icon fontSize="small">message</Icon>,
    route: "/system-message",
    component: <SystemMessage />,
  },
  {
    type: "collapse",
    name: "Настройки",
    key: "settings",
    icon: <Icon fontSize="small">tune</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "auth",
    name: "Авторизация",
    key: "login",
    icon: <Icon fontSize="small">Войти</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
];

export default routes;
