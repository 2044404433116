import HttpService from "./htttp.service";

class DataService {

  getInfo = async () => {
    return await HttpService.get('info');
  };

  //Пользователи
  getUsers = async () => {
    return await HttpService.get('users');
  };

  saveUser = async (payload) => {
    return await HttpService.put('users', payload);
  };

  deleteUser = async (payload) => {
    return await HttpService.delete('users', payload);
  };

  //Админы
  getAdmins = async () => {
    return await HttpService.get('admins');
  };

  addAdmins = async (payload) => {
    return await HttpService.post('admins', payload);
  };

  saveAdmins = async (payload) => {
    return await HttpService.put('admins', payload);
  };

  deleteAdmins = async (payload) => {
    return await HttpService.delete('admins', payload);
  };

  //Напоминания
  getSchedules = async () => {
    return await HttpService.get('schedules');
  };

  saveSchedules = async (payload) => {
    return await HttpService.put('schedules', payload);
  };

  deleteSchedule = async (payload) => {
    return await HttpService.delete('schedules', payload);
  };

  //Обращения
  getFeedback = async () => {
    return await HttpService.get('feedback');
  };

  addFeedbackReply = async (payload) => {
    return await HttpService.post('feedback', payload);
  };

  deleteFeedback = async (payload) => {
    return await HttpService.delete('feedback', payload);
  };

  //События
  getEvents = async () => {
    return await HttpService.get('events');
  };

  saveEvents = async (payload) => {
    return await HttpService.put('events', payload);
  };

  addEvents = async (payload) => {
    return await HttpService.post('events', payload);
  };

  deleteEvents = async (payload) => {
    return await HttpService.delete('events', payload);
  };

  //Новости
  getNews = async () => {
    return await HttpService.get('news');
  };

  saveNews = async (payload) => {
    return await HttpService.put('news', payload);
  };

  addNews = async (payload) => {
    return await HttpService.post('news', payload);
  };

  uploadImage = async (payload) => {
    let formData = new FormData();
    formData.append('image', payload, payload.name);
    return await HttpService.post('upload-image', formData);
  };

  deleteNews = async (payload) => {
    return await HttpService.delete('news', payload);
  };

  //Системные сообщения
  getMessages = async () => {
    return await HttpService.get('system-message');
  };

  saveMessages = async (payload) => {
    return await HttpService.put('system-message', payload);
  };

  addMessages = async (payload) => {
    return await HttpService.post('system-message', payload);
  };

  deleteMessages = async (payload) => {
    return await HttpService.delete('system-message', payload);
  };

  //Настройки
  getSettings = async () => {
    return await HttpService.get('settings');
  };
  saveSettings = async (payload) => {
    return await HttpService.put('settings', payload);
  };

  //Поликлиники
  getClinics = async () => {
    return await HttpService.get('clinics');
  };

  saveClinic = async (payload) => {
    return await HttpService.put('clinics', payload);
  };

  addClinic = async (payload) => {
    return await HttpService.post('clinics', payload);
  };

  deleteClinic = async (payload) => {
    return await HttpService.delete('clinics', payload);
  };

  sortClinic = async (payload) => {
    const id = payload.target.dataset.id;
    const sort = payload.target.value;
    return await HttpService.post('clinics/sort', {id, sort});
  };

  //Обращения поликлиник
  getClinicRequest = async () => {
    return await HttpService.get('clinics/requests');
  };

  addClinicRequestReply = async (payload) => {
    return await HttpService.post('clinics/requests', payload);
  };

  deleteClinicRequest = async (payload) => {
    return await HttpService.delete('clinics/requests', payload);
  };

  //Шаблона ответов
  getTemplates = async () => {
    return await HttpService.get('requests/templates');
  };

  saveTemplates = async (payload) => {
    return await HttpService.put('requests/templates', payload);
  };

  addTemplates = async (payload) => {
    return await HttpService.post('requests/templates', payload);
  };

  deleteTemplates = async (payload) => {
    return await HttpService.delete('requests/templates', payload);
  };

  //Меню
  getMenu = async () => {
    return await HttpService.get('menu');
  };

  saveMenu = async (payload) => {
    return await HttpService.put('menu', payload);
  };
}

export default new DataService();
