import React, { useRef, useImperativeHandle, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from "@mui/material/Switch";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ruRU } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextEditor from 'components/TextEditor';
import DropZone from 'components/DropZone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import './style.css'

export default function DialogMain({ show, handleClose, handleSave, keyOrig, orig, defaultValue, form, title, titleSave, answer = false }) {
  const formRef = useRef();
  let showDateTimeNum = 0;
  let showDateNum = 0;

  useImperativeHandle(formRef, () => ({
    submitForm() {
      handleSubmit(handleSave)();
    }
  }));

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const [form_s, setForm] = useState(form);
  const [elem, setElem] = useState({});
  const [showDateTime, setShowDateTime] = useState(form_s.filter(e => e.type == 'DateTimeCalendar').map(() => false));
  const [showDate, setShowDate] = useState(form_s.filter(e => e.type == 'DateCalendar').map(() => false));

  useEffect(() => {
    if (elem) {
      form_s.forEach(el => {
        if(el.name == 'password'){
          setValue(el.name, '');
        }else{
          setValue(el.name, elem[el.name]);
        }
      })
    }
    if(orig[keyOrig]){
      setElem(orig[keyOrig] || defaultValue);
    }
  }, [elem, orig[keyOrig], form_s]);

  const handleAddMultiple = (e) => {
    let index = false;
    if(e.target.tagName == 'BUTTON'){
      index = +e.target.dataset.index;
    }else if(e.target.tagName == 'SPAN'){
      index = +e.target.parentElement.dataset.index;
    }

    const fieldName = form_s[index].name.split('[')[0];
    elem[fieldName].push('');
    setElem(JSON.parse(JSON.stringify(elem)));
    setForm(JSON.parse(JSON.stringify(form_s)));
  }

  const handleDeleteMultiple = (e) => {
    let index = false, indexIn = false;
    if(e.target.tagName == 'BUTTON'){
      index = +e.target.dataset.index;
      indexIn = +e.target.dataset.indexin;
    }else if(e.target.tagName == 'SPAN'){
      index = +e.target.parentElement.dataset.index;
      indexIn = +e.target.parentElement.dataset.indexin;
    }
    const fieldName = form_s[index].name.split('[')[0];
    elem[fieldName].splice(indexIn, 1);
    unregister(form_s[index].name.replace(/\d+/g, indexIn));
    setElem(elem);
    setForm(JSON.parse(JSON.stringify(form_s)));
  }

  const searchDouble = (el) => {
    const fieldName = el.name.split('[')[0];
    return elem[fieldName] != undefined && elem[fieldName].length > 1 ? true : false;
  }
    
  return (
    <div>
      <Dialog open={show} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <DialogTitle>{title}</DialogTitle>
        { answer && (
          <DialogContent>
            <DialogContentText>{answer}</DialogContentText>
          </DialogContent>
        ) }
        <DialogContent>
            <form onSubmit={handleSubmit(handleSave)}>
             {
              form_s.map((el, i) => {
                let required = {};
                let required_date = false;
                if(typeof el.prop !== 'undefined' && el.prop.required){
                  required = { required: true };
                  required_date = true;
                }
                let error = errors[el.name] ? true : false;
                switch(el.type){
                  case 'hidden':
                    return <input type="hidden" name={el.name} value={elem[el.name]} {...register(el.name, required)}/>
                  case 'TextField':
                    if(Array.isArray(elem[el.name.split('[')[0]])){
                      const element = [];
                      elem[el.name.split('[')[0]].forEach((item, t) => {
                        const name = `${el.name.split('[')[0]}[${t}]`;
                        error = errors[name] ? true : false;
                        element.push(<FormGroup key={t}>
                                  <Grid container spacing={0} justifyContent="center" alignItems="center">
                                    <Grid item xs={ el.multiple ? 10 : 12 }>
                                      <TextField
                                        error={error}
                                        margin="dense"
                                        name={name}
                                        label={el.label}
                                        helperText={error ? 'Это поле должно быть заполнено' : ''}
                                        type={el.typeInput}
                                        fullWidth
                                        variant="standard"
                                        defaultValue={item}
                                        {...el.prop}
                                        {...register(name, required)}
                                      />
                                    </Grid>
                                    { el.multiple && (
                                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                                        <MDButton variant="gradient" color="success" size="small" data-index={i} onClick={handleAddMultiple} iconOnly type="button"><Icon fontSize="small">add</Icon></MDButton>
                                        { searchDouble(el) && (
                                          <MDButton variant="gradient" color="error" size="small" ml={2} style={{marginLeft: 10}} data-index={i} data-indexin={t} onClick={handleDeleteMultiple} iconOnly type="button"><Icon fontSize="small">remove</Icon></MDButton>
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                </FormGroup>)
                      })
                      return (<div>{element}</div>);
                    }else{
                      return (<FormGroup>
                              <Grid container spacing={0} justifyContent="center" alignItems="center">
                                <Grid item xs={ el.multiple ? 10 : 12 }>
                                  <TextField
                                    error={error}
                                    margin="dense"
                                    name={el.name}
                                    label={el.label}
                                    helperText={error ? 'Это поле должно быть заполнено' : ''}
                                    type={el.typeInput}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={elem[el.name.split('[')[0]]}
                                    {...el.prop}
                                    {...register(el.name, required)}
                                  />
                                </Grid>
                                { el.multiple && (
                                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                                    <MDButton variant="gradient" color="success" size="small" data-index={i} onClick={handleAddMultiple} iconOnly type="button"><Icon fontSize="small">add</Icon></MDButton>
                                    { searchDouble(el) && (
                                      <MDButton variant="gradient" color="error" size="small" ml={2} style={{marginLeft: 10}} data-index={i} data-indexIn={0} onClick={handleDeleteMultiple} iconOnly type="button"><Icon fontSize="small">remove</Icon></MDButton>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            </FormGroup>)
                    }
                  case 'TextFieldSelect':
                    return <FormGroup>
                              <TextField
                                error={error}
                                margin="dense"
                                name={el.name}
                                label={el.label}
                                fullWidth
                                select
                                variant="standard"
                                defaultValue={elem[el.name]}
                                helperText={error ? 'Это поле должно быть заполнено' : ''}
                                {...el.prop}
                                {...register(el.name, required)}
                              >
                                {
                                  el.items.map(item => {
                                    return <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                  })
                                }
                              </TextField>
                            </FormGroup>
                  case 'DateTimeCalendar':
                    showDateTimeNum++;
                    return <FormGroup className="dateTimeForm">
                              <FormControlLabel control={<Checkbox data-show={showDateTimeNum} onChange={ (e) => {
                                const index = (+e.target.parentElement.dataset.show) - 1;
                                showDateTime[index] = !showDateTime[index]
                                setShowDateTime([...showDateTime])
                              } } />} label={el.label} />
                              { showDateTime[showDateTimeNum - 1] && (
                                <LocalizationProvider 
                                  dateAdapter={AdapterDayjs}
                                  localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                  adapterLocale="ru"
                                >
                                    <MobileDateTimePicker
                                      margin="dense"
                                      fullWidth
                                      variant="standard"
                                      defaultValue={dayjs(elem[el.name])}
                                      format={el.format}
                                      disablePast={true}
                                      onChange={(value) => {
                                        setValue(el.name, Date.parse(value));
                                      }}
                                    />
                                </LocalizationProvider>
                              )}
                          </FormGroup>
                  case 'DateCalendar':
                    showDateNum++;
                    return <FormGroup className="dateTimeForm">
                            { required_date && (
                              <FormGroup className="dateTimeForm">
                                <Grid container spacing={6}>
                                  <Grid style={{display: "flex"}} item xs={12} sm={2}>
                                    <InputLabel
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                        alignItems: 'center'
                                      }}
                                    >{el.label}</InputLabel>
                                  </Grid>
                                  <Grid item xs={12} sm={10}>
                                    <LocalizationProvider 
                                      dateAdapter={AdapterDayjs}
                                      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                    >
                                        <DatePicker
                                          margin="dense"
                                          fullWidth
                                          variant="standard"
                                          defaultValue={dayjs(elem[el.name])}
                                          format={el.format}
                                          disablePast={true}
                                          slotProps={{
                                            textField: {
                                              helperText: error ? 'Это поле должно быть заполнено' : '',
                                              required: true
                                            },
                                          }}
                                          {...register(el.name, required)}
                                          onChange={(value) => {
                                            setValue(el.name, Date.parse(value));
                                          }}
                                        />
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                              </FormGroup>
                            )}
                            { !required_date && (
                              <div>
                                <FormControlLabel control={<Checkbox data-show={showDateNum} onChange={ (e) => {
                                  const index = (+e.target.parentElement.dataset.show) - 1;
                                  showDate[index] = !showDate[index]
                                  setShowDate([...showDate])
                                } } />} label={el.label} />
                                { showDate[showDateNum - 1] && (
                                  <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                  >
                                      <DatePicker
                                        margin="dense"
                                        fullWidth
                                        variant="standard"
                                        defaultValue={dayjs(elem[el.name])}
                                        format={el.format}
                                        disablePast={true}
                                        slotProps={{
                                          textField: {
                                            helperText: error ? 'Это поле должно быть заполнено' : '',
                                            required: true
                                          },
                                        }}
                                        onChange={(value) => {
                                          setValue(el.name, Date.parse(value));
                                        }}
                                      />
                                  </LocalizationProvider>
                                )}
                              </div>
                            )}
                          </FormGroup>
                  case 'TextEditor':
                    return <FormGroup>
                              <TextEditor
                                label={el.label}
                                inititalText={elem[el.name] ? elem[el.name] : ''}
                                helperText={error ? 'Это поле должно быть заполнено' : ''}
                                {...register(el.name, required)}
                                onChange={(value) => {
                                  setValue(el.name, value);
                                }}
                              />
                            </FormGroup>
                  case 'FilesUpload':
                    return  <FormGroup>
                              <DropZone
                                label={el.label}
                                fileType={el.fileType}
                                inititalFiles={elem[el.name]}
                                origFiles={elem[`${el.name}_orig`]}
                                {...register(el.name, required)}
                                onChange={(value) => {
                                  setValue(el.name, value);
                                }}
                              />
                            </FormGroup>
                  case 'Switch':
                    return <FormGroup>
                            <FormControlLabel
                              control={<Switch defaultChecked={parseInt(elem[el.name]) == 1 ? true : false}/>}
                              label={el.label}
                              {...register(el.name, required)}
                            />
                          </FormGroup>
                }
              })
             }
            </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          { titleSave && (
            <Button onClick={() => formRef.current.submitForm()}>{titleSave}</Button>
          ) }
        </DialogActions>
      </Dialog>
    </div>
  );
}