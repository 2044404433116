/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function SystemMessage() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Сообщение", accessor: "message", align: "left" },
    { Header: "Тип", accessor: "type", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextEditor',
      typeInput: 'text',
      label: 'Введите контент сообщения',
      name: 'message',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Тип',
      name: 'type',
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    message: '',
    type: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [messages, setMessages] = useState([]);
  const [messagesOrig, setMessagesOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [messagesEdit, setMessagesEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [messagesDelete, setMessagesDelete] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  
  const [messagesEditResponse, setMessagesEditResponse] = useState(false);
  const [messagesEditResponseShow, setMessagesEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(messagesEdit !== false){
      setShowEdit(true);
    }
    if(messagesEditResponse !== false){
      setMessagesEditResponseShow(true);
      setTimeout(setMessagesEditResponseShow, 3000, false);
    }
    if(messagesDelete !== false){
      setShowDelete(true);
    }
  }, [messagesEdit, messagesEditResponse, messagesDelete]);

  useEffect(async () => {
    const messages_d = await DataService.getMessages();
    if(messages_d){
      const clone = Object.assign([], messages_d);
      setMessagesOrig(clone);

      const messages_d_table = [];
      messages_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.message = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.message}</MDTypography>)
        e.type = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.type}</MDTypography>)
        e.date = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.date}</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-messages={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-messages={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        messages_d_table.push(e);
      })
      setMessages(messages_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let messages = false;
    if(e.target.tagName == 'BUTTON'){
      messages = e.target.dataset.messages;
    }else if(e.target.tagName == 'SPAN'){
      messages = e.target.parentElement.dataset.messages;
    }
    setMessagesEdit(+messages);
  };

  const handleShowDeleteModal = (e) => {
    let messages = false;
    if(e.target.tagName == 'BUTTON'){
      messages = e.target.dataset.messages;
    }else if(e.target.tagName == 'SPAN'){
      messages = e.target.parentElement.dataset.messages;
    }
    setMessagesDelete(+messages);
  };

  const handleShowAddModal = (e) => {
    setShowAdd(true);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowAdd(false);
    setMessagesEdit(false);
    setMessagesDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveMessages(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setMessagesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setMessagesEdit(false);
      setMessagesDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteMessages(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setMessagesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setMessagesEdit(false);
      setMessagesDelete(false);
    }
  };

  const handleAddModal = async (e) => {
    const res = await DataService.addMessages(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setMessagesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setMessagesEdit(false);
      setMessagesDelete(false);
    }
  };

  const toggleSnackbar = () => setMessagesEditResponseShow(!messagesEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Системные сообщения
                  <MDBox display="inline" ml={2}>
                    <MDButton variant="gradient" color="success" size="small" pr={2} iconOnly type="button" onClick={handleShowAddModal} ml={1}><Icon fontSize="small">add</Icon></MDButton>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: messages }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={messagesEdit}
        orig={messagesOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование системного сообщения"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={messagesDelete}
        orig={messagesOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление системного сообщения"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить системное сообщение?!"
      />
      <DialogMain
        show={showAdd}
        handleClose={handleCloseModal}
        handleSave={handleAddModal}
        keyOrig={0}
        orig={[]}
        form={formSave}
        defaultValue={defaultForm}
        title="Добавление системного сообщения"
        titleSave="Добавить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с системным сообщением"
        dateTime='Только что'
        content={messagesEditResponse}
        open={messagesEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default SystemMessage;
