/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import Chat from 'components/Chat';
import DialogMain from 'components/DialogMain';

function RequestsClinic() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Поликлиника", accessor: "clinic", align: "left", isSorted: true },
    { Header: "Пользователь", accessor: "client", align: "left" },
    { Header: "Ответ", accessor: "reply_text", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    type: 1,
    messages: [],
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [clinicRequests, setClinicRequest] = useState([]);
  const [clinicRequestsOrig, setClinicRequestOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [clinicRequestsEdit, setClinicRequestEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [clinicRequestsDelete, setClinicRequestDelete] = useState(false);
  
  const [clinicRequestsEditResponse, setClinicRequestEditResponse] = useState(false);
  const [clinicRequestsEditResponseShow, setClinicRequestEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(clinicRequestsEdit !== false){
      setShowEdit(true);
    }
    if(clinicRequestsEditResponse !== false){
      setClinicRequestEditResponseShow(true);
      setTimeout(setClinicRequestEditResponseShow, 3000, false);
    }
    if(clinicRequestsDelete !== false){
      setShowDelete(true);
    }
  }, [clinicRequestsEdit, clinicRequestsEditResponse, clinicRequestsDelete]);

  useEffect(async () => {
    const clinicRequests_d = await DataService.getClinicRequest();
    if(clinicRequests_d){
      const clone = Object.assign([], clinicRequests_d);
      setClinicRequestOrig(clone);

      const clinicRequests_d_table = [];
      clinicRequests_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        if(e.messages[e.messages.length - 1].reply.length){
          e.reply_text = (<MDBadge badgeContent="Ответ отправлен" color="success" variant="gradient" size="sm" data-key={i} />)
        }else {
          e.reply_text = (<MDBadge badgeContent="Ответ не отправлен" color="warning" variant="gradient" size="sm" data-key={i} />)
        }
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.client = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.client}</MDTypography>)
        e.clinic = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.clinic}</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <Tooltip title="Открыть переписку и ответить">
                <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowModal} data-requests={i}><Icon fontSize="small">edit</Icon></MDButton>
              </Tooltip>
            </MDBox>
            <Tooltip title="Удалить">
              <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-requests={i}><Icon fontSize="small">delete</Icon></MDButton>
            </Tooltip>
          </MDBox>
        ];
        
        clinicRequests_d_table.push(e);
      })
      setClinicRequest(clinicRequests_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let clinicRequests = false;
    if(e.target.tagName == 'BUTTON'){
      clinicRequests = e.target.dataset.requests;
    }else if(e.target.tagName == 'SPAN'){
      clinicRequests = e.target.parentElement.dataset.requests;
    }
    setClinicRequestEdit(+clinicRequests);
  };

  const handleShowDeleteModal = (e) => {
    let clinicRequests = false;
    if(e.target.tagName == 'BUTTON'){
      clinicRequests = e.target.dataset.requests;
    }else if(e.target.tagName == 'SPAN'){
      clinicRequests = e.target.parentElement.dataset.requests;
    }
    setClinicRequestDelete(+clinicRequests);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setClinicRequestEdit(false);
    setClinicRequestDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.addClinicRequestReply(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setClinicRequestEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setClinicRequestEdit(false);
      setClinicRequestDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteClinicRequest(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setClinicRequestEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setClinicRequestEdit(false);
      setClinicRequestDelete(false);
    }
  };

  const toggleSnackbar = () => setClinicRequestEditResponseShow(!clinicRequestsEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Обращения пользователей в поликлинику
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: clinicRequests }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Chat
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={clinicRequestsEdit}
        orig={clinicRequestsOrig}
        defaultValue={defaultForm}
        title="История сообщений с "
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={clinicRequestsDelete}
        orig={clinicRequestsOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление обращения"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить обращение?!"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с обращением"
        dateTime='Только что'
        content={clinicRequestsEditResponse}
        open={clinicRequestsEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default RequestsClinic;
