/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function TemplatesReply() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Шаблон", accessor: "template_new", align: "left" },
    { Header: "Ответ", accessor: "reply", align: "left" },
    { Header: "Добавлен", accessor: "createdAt", align: "left" },
    { Header: "Действия", accessor: "action", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Шаблон',
      name: 'template',
      prop: {
        helperText: 'Вводить строго через запятую',
      }
    },
    {
      type: 'TextEditor',
      typeInput: 'text',
      label: 'Ответ',
      name: 'reply',
    },
  ];
  const formDelete = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    }
  ];
  const defaultForm = {
    id: 0,
    template: '',
    reply: '',
  };
  const defaultFormDelete = {
    id: 0,
  };

  const [templates, setTemplates] = useState([]);
  const [templatesOrig, setTemplatesOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [templatesEdit, setTemplatesEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [templatesDelete, setTemplatesDelete] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  
  const [templatesEditResponse, setTemplatesEditResponse] = useState(false);
  const [templatesEditResponseShow, setTemplatesEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(templatesEdit !== false){
      setShowEdit(true);
    }
    if(templatesEditResponse !== false){
      setTemplatesEditResponseShow(true);
      setTimeout(setTemplatesEditResponseShow, 3000, false);
    }
    if(templatesDelete !== false){
      setShowDelete(true);
    }
  }, [templatesEdit, templatesEditResponse, templatesDelete]);

  useEffect(async () => {
    const templates_d = await DataService.getTemplates();
    if(templates_d){
      const clone = Object.assign([], templates_d);
      setTemplatesOrig(clone);

      const events_d_table = [];
      templates_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        e.createdAt = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.createdAt}</MDTypography>)
        e.template_new = e.template_new.map((t, r) => <MDBadge badgeContent={t} color="success" variant="gradient" size="sm" data-key={r} />)
        e.reply = (<MDTypography variant="caption" color="text" fontWeight="medium">{e.reply}</MDTypography>)
        e.action = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <MDButton variant="gradient" color="info" size="small" pr={2} iconOnly type="button" onClick={handleShowModal} data-templates={i}><Icon fontSize="small">edit</Icon></MDButton>
            </MDBox>
            <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowDeleteModal} data-templates={i}><Icon fontSize="small">delete</Icon></MDButton>
          </MDBox>
        ];
        
        events_d_table.push(e);
      })
      setTemplates(events_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let templates = false;
    if(e.target.tagName == 'BUTTON'){
      templates = e.target.dataset.templates;
    }else if(e.target.tagName == 'SPAN'){
      templates = e.target.parentElement.dataset.templates;
    }
    setTemplatesEdit(+templates);
  };

  const handleShowDeleteModal = (e) => {
    let templates = false;
    if(e.target.tagName == 'BUTTON'){
      templates = e.target.dataset.templates;
    }else if(e.target.tagName == 'SPAN'){
      templates = e.target.parentElement.dataset.templates;
    }
    setTemplatesDelete(+templates);
  };

  const handleShowAddModal = (e) => {
    setShowAdd(true);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowAdd(false);
    setTemplatesEdit(false);
    setTemplatesDelete(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveTemplates(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setTemplatesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setTemplatesEdit(false);
      setTemplatesDelete(false);
    }
  };

  const handleDeleteModal = async (e) => {
    const res = await DataService.deleteTemplates(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setTemplatesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setTemplatesEdit(false);
      setTemplatesDelete(false);
    }
  };

  const handleAddModal = async (e) => {
    const res = await DataService.addTemplates(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setTemplatesEditResponse(res.message);
      setShowEdit(false);
      setShowDelete(false);
      setShowAdd(false);
      setTemplatesEdit(false);
      setTemplatesDelete(false);
    }
  };

  const toggleSnackbar = () => setTemplatesEditResponseShow(!templatesEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Шаблоны ответов
                  <MDBox display="inline" ml={2}>
                    <MDButton variant="gradient" color="success" size="small" pr={2} iconOnly type="button" onClick={handleShowAddModal} ml={1}><Icon fontSize="small">add</Icon></MDButton>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: templates }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={templatesEdit}
        orig={templatesOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование шаблона"
        titleSave="Сохранить"
      />
      <DialogMain
        show={showDelete}
        handleClose={handleCloseModal}
        handleSave={handleDeleteModal}
        keyOrig={templatesDelete}
        orig={templatesOrig}
        form={formDelete}
        defaultValue={defaultFormDelete}
        title="Удаление шаблона"
        titleSave="Удалить"
        answer="Вы действительно хотите удалить шаблон?!"
      />
      <DialogMain
        show={showAdd}
        handleClose={handleCloseModal}
        handleSave={handleAddModal}
        keyOrig={0}
        orig={[]}
        form={formSave}
        defaultValue={defaultForm}
        title="Добавление шаблона"
        titleSave="Добавить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с шаблоном"
        dateTime='Только что'
        content={templatesEditResponse}
        open={templatesEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default TemplatesReply;
