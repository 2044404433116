import HttpService from "./htttp.service";

class BotService {
  // authEndpoint = process.env.API_URL;

  off = async () => {
    return await HttpService.post('bot/off');
  };

  on = async () => {
    return await HttpService.post('bot/on');
  };

  restart = async() => {
    return await HttpService.post('bot/restart');
  }
}

export default new BotService();
