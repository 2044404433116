/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function Menu() {
  const columns = [
    { Header: "id", accessor: "id", width: '5%', align: "left" },
    { Header: "Заголовок", accessor: "title", align: "left" },
    { Header: "Сортировка", accessor: "sort", align: "left", isSorted: true },
    { Header: "Статус", accessor: "status", align: "left" },
    { Header: "Действие", accessor: "action", align: "left" },
    { Header: "Действия", accessor: "actions", align: "left" },
  ];
  const formSave = [
    {
      type: 'hidden',
      typeInput: 'hidden',
      name: 'id',
    },
    {
      type: 'TextField',
      typeInput: 'text',
      label: 'Заголовок',
      name: 'title',
      prop: {
        required: true
      }
    },
    {
      type: 'TextField',
      typeInput: 'number',
      label: 'Сортировка',
      name: 'sort',
      prop: {
        required: true
      }
    },
    {
      type: 'Switch',
      label: 'Статус',
      name: 'status',
      prop: {
        required: true
      }
    },
    {
      type: 'TextField',
      label: 'Действие',
      name: 'action',
      prop: {
        required: true,
        disabled: true
      }
    },
  ];
  const defaultForm = {
    id: 0,
    title: '',
    sort: 0,
    status: 0,
    action: ''
  };

  const [menu, setMenu] = useState([]);
  const [menuOrig, setMenuOrig] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [menuEdit, setMenuEdit] = useState(false);
  
  const [menuEditResponse, setMenuEditResponse] = useState(false);
  const [menuEditResponseShow, setMenuEditResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if(menuEdit !== false){
      setShowEdit(true);
    }
    if(menuEditResponse !== false){
      setMenuEditResponseShow(true);
      setTimeout(setMenuEditResponseShow, 3000, false);
    }
  }, [menuEdit, menuEditResponse]);

  useEffect(async () => {
    const menu_d = await DataService.getMenu();
    if(menu_d){
      const clone = Object.assign([], menu_d);
      setMenuOrig(clone);

      const menu_d_table = [];
      menu_d.forEach((el, i) => {
        const e = Object.assign({}, el);
        if(e.status == 1){
          e.status = (<MDBadge badgeContent="Вкл." color="success" variant="gradient" size="sm" data-key={i} />)
        }else {
          e.status = (<MDBadge badgeContent="Выкл." color="warning" variant="gradient" size="sm" data-key={i} />)
        }
        e.actions = [
          <MDBox display="flex" pr={2}>
            <MDBox pr={1}>
              <Tooltip title="Изменить">
                <MDButton variant="gradient" color="info" size="small" iconOnly type="button" onClick={handleShowModal} data-menu={i}><Icon fontSize="small">edit</Icon></MDButton>
              </Tooltip>
            </MDBox>
          </MDBox>
        ];
        
        menu_d_table.push(e);
      })
      setMenu(menu_d_table);
    }
  }, [refreshKey]);

  const handleShowModal = (e) => {
    let menu = false;
    if(e.target.tagName == 'BUTTON'){
      menu = e.target.dataset.menu;
    }else if(e.target.tagName == 'SPAN'){
      menu = e.target.parentElement.dataset.menu;
    }
    setMenuEdit(+menu);
  };

  const handleCloseModal = () => {
    setShowEdit(false);
    setMenuEdit(false);
  };

  const handleSaveModal = async (e) => {
    const res = await DataService.saveMenu(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1)
      setMenuEditResponse(res.message);
      setShowEdit(false);
      setMenuEdit(false);
    }
  };

  const toggleSnackbar = () => setMenuEditResponseShow(!menuEditResponseShow);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Меню бота
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: menu }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DialogMain
        show={showEdit}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        keyOrig={menuEdit}
        orig={menuOrig}
        form={formSave}
        defaultValue={defaultForm}
        title="Редактирование меню"
        titleSave="Сохранить"
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с меню"
        dateTime='Только что'
        content={menuEditResponse}
        open={menuEditResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Menu;
