/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { set, useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataService from "services/data-service";

import DialogMain from 'components/DialogMain';

function Settings() {
  const [settings, setSettings] = useState([]);
  
  const [settingsResponse, setSettingsResponse] = useState(false);
  const [settingsResponseShow, setSettingsResponseShow] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(async () => {
    const settings = await DataService.getSettings();
    if(settings){
      const settings_save = [];
      settings.forEach((el, i) => {
        el.name = el.type;
        switch(el.type){
          case 'send_news':
          case 'send_events':
            el.type = 'switch';
            break;
          case 'time_work':
          case 'time_schedule':
            el.type = 'string';
            break;
          case 'column_news':
          case 'wait_clinic_mute':
            el.type = 'number';
            break;
          case 'weekend':
            el.type = 'checkbox';
            break;
        }
        settings_save.push(el)
      })
      setSettings(settings_save);
    }

    if(settingsResponse !== false){
      setSettingsResponseShow(true);
      setTimeout(setSettingsResponseShow, 3000, false);
    }
  }, [refreshKey, settingsResponse]);

  const handleSave = async (e) => {
    e.weekend = e.weekend.map(w => w ? 1 : 0);
    const res = await DataService.saveSettings(e);
    if(res && res.status){
      setRefreshKey(oldKey => oldKey +1);
      setSettingsResponse(res.message);
    }
  };

  const toggleSnackbar = () => setSettingsResponseShow(!settingsResponseShow);

  const formRef = useRef();

  useImperativeHandle(formRef, () => ({
    submitForm() {
      handleSubmit(handleSave)();
    }
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">Настройки</MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit(handleSave)}>
                  {settings.map((setting) => {
                    const props = {
                      defaultValue: setting.value,
                      defaultChecked: setting.value == 1 ? true : false,
                      name: setting.name
                    };
                    switch(setting.type){
                      case 'string':
                      case 'number':
                        return (<FormGroup><FormControlLabel control={<TextField {...props} variant="standard" type={setting.type} {...register(props.name)} />} labelPlacement="start" label={setting.label} /></FormGroup>)
                      case 'switch':
                        return (<FormGroup><FormControlLabel control={<Switch {...props} />} label={setting.label} {...register(props.name)} /></FormGroup>)
                      case 'checkbox':
                        return <FormGroup><FormLabel component="legend">{setting.label}</FormLabel>{
                          setting.value.map((v, i) => {
                            let label_d = '';
                            switch(i){
                              case 0:
                                label_d = 'пн.';
                                break;
                              case 1:
                                label_d = 'вт.';
                                break;
                              case 2:
                                label_d = 'ср.';
                                break;
                              case 3:
                                label_d = 'чт.';
                                break;
                              case 4:
                                label_d = 'пт.';
                                break;
                              case 5:
                                label_d = 'сб.';
                                break;
                              case 6:
                                label_d = 'вс.';
                                break;
                            }
                            const el = v == 1 ? <Checkbox defaultChecked {...register(`${props.name}[${i}]`)} /> : <Checkbox {...register(`${props.name}[${i}]`)} />;
                            return <FormControlLabel control={el} label={label_d} />
                          })
                          }</FormGroup>
                    }
                  })}
                  <Button onClick={() => formRef.current.submitForm()}>Сохранить</Button>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDSnackbar
        color="info"
        icon="notifications"
        title="Действие с настройками"
        dateTime='Только что'
        content={settingsResponse}
        open={settingsResponseShow}
        close={toggleSnackbar}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
