import './style.css'

import { Color } from '@tiptap/extension-color'
import { Placeholder } from '@tiptap/extension-placeholder'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'

import Button from '@mui/material/Button';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className='textEditor-Menu'>
      <Button
        size="small"
        variant="outlined"
        color={editor.isActive('bold') ? 'success' : 'primary'}
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        Жирный
      </Button>
      <Button
        size="small"
        variant="outlined"
        color={editor.isActive('italic') ? 'success' : 'primary'}
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        Курсив
      </Button>
      <Button
        size="small"
        variant="outlined"
        color={editor.isActive('strike') ? 'success' : 'primary'}
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        Зачёркнутый
      </Button>
      <Button
        size="small"
        variant="outlined"
        type="button"
        onClick={() => editor.chain().focus().unsetAllMarks().run() && editor.chain().focus().clearNodes().run()}
      >
        Очистить
      </Button>
    </div>
  )
}

export default function TextEditor({ inititalText, label, onChange, helperText }){
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name], HTMLAttributes: [] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        placeholder: label,
      })
    ],
    content: inititalText,
    onCreate({ editor }) {
      const html = inititalText.replace(/(\r\n|\r|\n)/g, "<br>");
      editor.commands.setContent(html);
    },
    onUpdate({ editor }) {
      const html = editor.getHTML().replace(/<[\/]*span(.*?)>/g, '').replace(/&nbsp;/g, "<br>").replace(/<p>/g, "").replace(/<\/p>/g, "<br>").replace(/<br>/g, "\n");

      onChange(html);
    },
  })

  return (
    <div className='textEditor'>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
      {helperText && (<p className='helper-text'>{helperText}</p>)}
    </div>
  )
}